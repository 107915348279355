import React from "react";
import { FiFileText } from "react-icons/fi";
import { FaFileImage } from "react-icons/fa6";

const DisplayAttachment = ({ name, url, isDoc, isImage }) => {
  return (
    <div
      className={`${isImage && url && "uploaded-file-image-container"} ${
        isDoc && "uploaded-file-item-container"
      }`}
    >
      {isDoc && (
        <div className="uploaded-file-detail">
          <span className="icon-container doc">
            <FiFileText className="icon" size={"18px"} />
          </span>
          <span className="file-name">{name}</span>
        </div>
      )}
      {isImage &&
        (url ? (
          <img src={url} className="uploaded-file-image" />
        ) : (
          <div className="uploaded-file-detail">
            <span className="icon-container unknown">
              <FaFileImage className="icon" size={"18px"} />
            </span>
            <span className="file-name">{name}</span>
          </div>
        ))}
    </div>
  );
};

export default DisplayAttachment;
