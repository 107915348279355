const initialState = {
  sessionId: null,
  toggleSidebar: true,
  feedbackModalVisible: false,
  feedbackConversationId: null,
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case "update_session":
      return { ...state, sessionId: action.sessionId };
    case "toggle_sidebar":
      return { ...state, toggleSidebar: action.toggleSidebar };
    case "feedback_modal":
      return {
        ...state,
        feedbackModalVisible: action.feedbackModalVisible,
        feedbackConversationId: action.feedbackConversationId,
      };
    default:
      return;
  }
};

export default reducers;
