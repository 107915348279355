import "../../styles/input-box-style.css";

import React, { useEffect, useRef, useState } from "react";
import { TbSend } from "react-icons/tb";
import { TbFileUpload } from "react-icons/tb";
import { CgAttachment } from "react-icons/cg";
import { toast } from "react-hot-toast";

import { extractExt } from "../../helper";
import AddedAttachment from "./AddedAttachment";
import { Config } from "../../config";
import { custom_style } from "../../styles/custom_style";

const InputAction = ({
  text,
  onInput,
  onSend,
  error,
  regenerateMessage,
  isDisabled,
  isFeedback,
}) => {
  const [showAttachmentList, setShowAttachmentList] = useState(false);
  const [addedAttachment, setAddedAttachment] = useState([]);
  const [placeholderText, setPlaceholderText] = useState("");
  const [closeIconVisible, setCloseIconVisible] = useState(-1);
  const containerRef = useRef(null);
  const fileInputRef = useRef(null);
  const textareaRef = useRef(null);

  //#region Add Attachment Function
  const handleFileChange = (event) => {
    const files = event.target.files;
    if (addedAttachment.length < 3) {
      if (files.length > 0) {
        // Handle the uploaded files here
        for (let i = 0; i < files.length; i++) {
          const fileSizeMB = files[i].size / (1024 * 1024);
          if (fileSizeMB > 5) {
            toast.error(
              "Please upload file lesser than 5mb!",
              custom_style.toast_error
            );
            event.target.value = "";
            return;
          }
          if (
            Config.IMAGE_EXTENSION.concat(Config.DOC_EXTENSION).includes(
              extractExt(files[i]?.type)
            )
          ) {
            setAddedAttachment([...addedAttachment, files[i]]);
          }
        }
      }
    } else {
      toast.error("Only 3 attachments are allowed!", custom_style.toast_error);
    }
    event.target.value = "";
  };
  //#endregion

  //#region Deleted Added Attachment
  const deleteAddedAttachment = (item) => {
    const deletedItem = addedAttachment.filter((i) => i !== item);
    setAddedAttachment(deletedItem);
    setCloseIconVisible(-1);
  };
  //#endregion

  //Change placeholder on screen change to mobile or web
  const handleScreenResize = () => {
    if (window.innerWidth < 750) {
      setPlaceholderText("");
    } else {
      setPlaceholderText("(Press shift + enter for next line)");
    }
  };

  //#region Dynamic input field
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height
      textarea.style.height = `${textarea?.scrollHeight}px`; // Set to scrollHeight
    }
  };
  //#endregion

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowAttachmentList(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleScreenResize);
    handleScreenResize();

    return () => window.removeEventListener("resize", handleScreenResize);
  }, []);

  useEffect(() => {
    adjustTextareaHeight(); // Adjust height when the text changes
  }, [text]);

  return (
    <div className="input-container" id="input-container" ref={containerRef}>
      {error && (
        <div className="regenerate-message-container">
          <p className="error-message-text">{error}</p>
          <button onClick={() => regenerateMessage()}>
            Regenerate response
          </button>
        </div>
      )}
      <div
        className={`input-text-area-container ${
          (addedAttachment.length > 0 ||
            textareaRef?.current?.scrollHeight > 32) &&
          "increased-height"
        }`}
        style={{ paddingTop: addedAttachment.length > 0 && 10 }}
      >
        {/* Display added attachments */}
        {addedAttachment?.length > 0 && (
          <div className="uploaded-file-container">
            {addedAttachment?.map((item, index) => {
              let extension = extractExt(item?.type);
              return (
                <AddedAttachment
                  key={index}
                  onHover={() => setCloseIconVisible(index)}
                  onLeave={() => setCloseIconVisible(-1)}
                  deleteItem={() => deleteAddedAttachment(item)}
                  isHovered={index === closeIconVisible}
                  imgUrl={URL.createObjectURL(item)}
                  name={item?.name}
                  isImage={Config.IMAGE_EXTENSION.includes(extension)}
                  isDoc={Config.DOC_EXTENSION.includes(extension)}
                />
              );
            })}
          </div>
        )}
        {/* User input field */}
        <textarea
          ref={textareaRef}
          placeholder={
            isFeedback
              ? "Please provide your valuable feedback..."
              : `Start typing your query ${placeholderText}`
          }
          className={`input-textbox ${isFeedback && "feedback"}`}
          value={text}
          onChange={(e) => {
            onInput(e.target.value);
            adjustTextareaHeight();
          }}
          id="input-textbox"
          onKeyDown={(event) => {
            if (event.key.toLowerCase() === "enter" && !event.shiftKey) {
              event.preventDefault();
              if (text.trim() !== "" && !isDisabled) {
                onSend(addedAttachment);
                setAddedAttachment([]);
              }
            }
          }}
          onFocus={() => setShowAttachmentList(false)}
          rows={1}
        ></textarea>
        {/* Buttons on input field container */}
        <div
          className={`input-text-area-buttons-container ${
            isFeedback && "feedback"
          }`}
        >
          <button
            className="add-attachment-button"
            id="add-attachment-button"
            type="button"
            onClick={() => {
              setShowAttachmentList(!showAttachmentList);
            }}
          >
            <CgAttachment className="add-attachment" />
          </button>
          <button
            className={`send-chat-button ${text.trim() === "" && "disabled"}`}
            id="send-chat-button"
            type="submit"
            onClick={() => {
              onSend(addedAttachment);
              setAddedAttachment([]);
            }}
            disabled={text.trim() === "" || isDisabled}
          >
            <TbSend className="send-icon" />
          </button>

          {showAttachmentList && (
            <div className="attachment-popup">
              <span
                className="attachment-list"
                onClick={() => {
                  fileInputRef.current.click();
                  setShowAttachmentList(false);
                }}
              >
                <TbFileUpload className="upload-icon" />
                <p>Upload from computer</p>
              </span>
            </div>
          )}
        </div>
      </div>

      {/* File input field */}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        // multiple
        accept={Config.IMAGE_EXTENSION.concat(Config.DOC_EXTENSION)
          .map((i) => `.${i}`)
          .join()}
      />
    </div>
  );
};

export default InputAction;
