import axios from "axios";
import { Config } from "../config";

const baseURL_Path = Config.API_URL;

const instance = axios.create({
  baseURL: baseURL_Path,
  headers: {
    "Content-Type": "application/json",
  },
});

export { instance };
