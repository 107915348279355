// import style
import "./App.css";
import "./styles/bot-style.css";

import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";

// import components
import Chatbox from "./screens/Chatbox";
import StartChatbox from "./screens/StartChatbox";
import { store } from "./redux/store";
import { Sidebar } from "./components";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function App() {
  const query = useQuery();

  // Added Password Auth to Access Site
  useEffect(() => {
    if (query.get("pass")) {
      window.localStorage.setItem("authPassword", query.get("pass"));
    }
  }, [query]);

  if (query.get("pass") !== "neoajackus") {
    return (
      <div style={{ textAlign: "center" }}>
        <h1 style={{ fontSize: "4em", color: "#fff" }}>Neo AI</h1>
      </div>
    );
  }

  return (
    <Provider store={store}>
      <div className="App">
        <Toaster />
        <Sidebar />
        <Routes>
          <Route path="/" element={<StartChatbox />} />
          <Route path="/conversation/:conversationId" element={<Chatbox />} />
        </Routes>
      </div>
    </Provider>
  );
}

export default App;
