import React, { useState } from "react";

import {
  ArrayObjectWithUrl,
  RenderArrayForInput,
  RenderArrayForTable,
  RenderArrayList,
  RenderArrayWithObject,
} from "../HelperComponents";
import { useSelector } from "react-redux";
import Markdown from "markdown-to-jsx";
import { toInternationalFormat } from "../../helper";
import { Config } from "../../config";

const DisplayMessages = ({
  message,
  questions,
  teamStructure,
  technicalSpecifications,
  caseStudies,
  costEstimation,
  estimation,
  potentialRisks,
  notes,
  suggestions,
  assumptions,
  onSubmit,
  isLast,
  isVisible,
  hideQuestions
}) => {
  const [userQuery, setUserQuery] = useState([]);
  const { sessionId } = useSelector((state) => state);
  let storedSessionId =
    JSON.parse(window.localStorage.getItem("sessionIds")) || [];

  const handleInputUserQuery = (index, question, answer) => {
    // Update the input at the specific index
    const newInputs = [...userQuery];
    newInputs[index] = { question: question, answer: answer };
    setUserQuery(newInputs);
  };

  return (
    <div className="botui-message-content bot">
      <div className="bot-response-text-content">
        {/* <div className="bot-response-text">{currentAi?.message ?? ""}</div> */}
        <Markdown>{message}</Markdown>
        {/* <div>
          <pre>{message}</pre>
        </div> */}
        {/* Questions */}
        {questions && questions?.questions?.length > 0  && !hideQuestions && (
          <div className="bot-response-object">
            <h2 className="bot-response-header">Questions</h2>
            <ol>
              <RenderArrayForInput
                data={questions}
                inputData={(index, question, answer) =>
                  handleInputUserQuery(index, question, answer)
                }
                value={userQuery}
                isVisible={isLast && storedSessionId?.includes(sessionId)}
              />
              {storedSessionId?.includes(sessionId) && isLast && (
                <button
                  className="bot-response-submit-button"
                  onClick={() => {
                    if (userQuery.length > 0) {
                      onSubmit(userQuery);
                    }
                  }}
                >
                  Submit
                </button>
              )}
            </ol>
          </div>
        )}
        {/* Team Structure */}
        {isVisible && teamStructure && teamStructure?.length > 0 && (
          <div className="bot-response-object">
            <h2 className="bot-response-header">Team Structure</h2>
            <ul style={{ listStyleType: "none" }}>
              {<RenderArrayForTable data={teamStructure} orderOfColumn={['role', 'description']}/>}
            </ul>
          </div>
        )}
        {/* Cost Estimation */}
        {isVisible && costEstimation && costEstimation?.totalCost > 0 && (
          <div className="bot-response-object">
            <h2 className="bot-response-header">Cost Estimation</h2>
            <ul>
              <li className="bot-response-list">
                <b>Total Cost:</b>{" "}
                {`${Config.IND_CURRENCY}${toInternationalFormat(costEstimation?.totalCost)}` ?? ""}
              </li>
            </ul>
            <ul style={{ listStyleType: "none" }}>
              {costEstimation?.breakdown?.length > 0 && (
                <RenderArrayForTable data={costEstimation?.breakdown} orderOfColumn={['role', 'numberOfResourcesRequired', 'costPerResource', 'totalEffortHours', 'total']} />
              )}
            </ul>
          </div>
        )}
        {/* Technical Specifications */}
        {isVisible &&
          technicalSpecifications &&
          technicalSpecifications?.length > 0 && (
            <div className="bot-response-object">
              <h2 className="bot-response-header">Technical Specifications</h2>
              <ul>
                {<RenderArrayWithObject data={technicalSpecifications} />}
              </ul>
            </div>
          )}
        {/* Case Studies */}
        {isVisible && caseStudies && caseStudies?.length > 0 && (
          <div className="bot-response-object">
            <h2 className="bot-response-header">Case Study</h2>
            <ul>
              {
                <ArrayObjectWithUrl
                  data={caseStudies}
                  openLink={(link) => link && window.open(link, "_blank")}
                />
              }
            </ul>
          </div>
        )}
        {/* Potential Risks */}
        {isVisible && potentialRisks && potentialRisks?.length > 0 && (
          <div className="bot-response-object">
            <h2 className="bot-response-header">Potential Risks</h2>
            <ul>
              <RenderArrayList data={potentialRisks} />
            </ul>
          </div>
        )}
        {/* Suggestions */}
        {isVisible && suggestions && suggestions?.length > 0 && (
          <div className="bot-response-object">
            <h2 className="bot-response-header">Suggestions</h2>
            <ul>{<RenderArrayWithObject data={suggestions} />}</ul>
          </div>
        )}
        
        {/* Estimations */}
        {isVisible && estimation && (
          <div className="bot-response-object">
            <h2 className="bot-response-header">Estimation</h2>
            <ul>
              <li className="bot-response-list">{estimation ?? ""}</li>
            </ul>
          </div>
        )}
        {/* Assumptions */}
        {isVisible && assumptions && assumptions?.length > 0 && (
          <div className="bot-response-object">
            <h2 className="bot-response-header">Assumptions </h2>
            <ul>
              <RenderArrayList data={assumptions} />
            </ul>
          </div>
        )}
        {/* Note */}
        {isVisible && notes && notes?.length > 0 && (
          <div className="bot-response-object">
            <h2 className="bot-response-header">Note </h2>
            <ul>
              <RenderArrayList data={notes} />
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default DisplayMessages;
