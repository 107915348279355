// Import Styles
import "../styles/style.css";
import "../styles/loader.css";
import "../styles/main-loader.css";
import { custom_style } from "../styles/custom_style";

// Import Components
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import { instance as axios } from "../lib/axios";
import RenderBot from "../components/CustomBot/RenderBot";
import { InputAction } from "../components";

const Chatbox = ({}) => {
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState({
    currentId: "",
    currentMessage: "",
    currentAttachments: [],
    chatHistory: [],
    feedback: null,
  });
  const [submittedAnswers, setSubmittedAnswers] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const [conversationConcludeCheck, setConversationConcludeCheck] =
    useState(false);
  const [isConversationConclude, setIsConversationConclude] = useState(false);
  const [error, setError] = useState("");
  const { conversationId } = useParams();
  const dispatch = useDispatch();
  let storedSessionId =
    JSON.parse(window.localStorage.getItem("sessionIds")) || [];

  const data = useSelector((state) => state);

  useEffect(() => {
    setInputText("");
    if (conversationId) {
      dispatch({ type: "update_session", sessionId: conversationId });
      fetchChatHistory(conversationId);
    }
  }, [conversationId]);

  //#region Api for asking query
  const sendToAPI = async (
    humanText,
    attachments,
    submittedAnswer = null,
    isConversationConcluded = false
  ) => {
    try {
      setError("");
      if (isLoading) {
        return;
      }
      setInputText("");
      const formData = new FormData();
      formData.append("user", submittedAnswer ?? humanText);
      formData.append("conversation_id", conversationId ?? "");
      formData.append("is_conversation_conclude", isConversationConcluded);

      if (attachments && attachments.length > 0) {
        attachments.forEach((attachment, index) => {
          formData.append(`attachment_${index + 1}`, attachment);
        });
      }

      setConversationConcludeCheck(isConversationConcluded);
      setSubmittedAnswers(submittedAnswer ?? null);

      setMessages((prevVal) => ({
        ...prevVal,
        currentId: conversationId,
        currentMessage: humanText,
        currentAttachments: attachments,
      }));
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("/conversations", formData, config);

      setMessages((prevVal) => ({
        ...prevVal,
        currentMessage: "",
        currentAttachments: [],
        chatHistory: [
          ...messages?.chatHistory,
          {
            user: data?.user,
            attachments: data?.attachments,
            ai: data?.ai,
            graph: data?.ai?.timeline || data?.graph,
          },
        ],
      }));
      setIsConversationConclude(!!data?.ai?.timeline);
      setSubmittedAnswers(null);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("Error", err?.message);
      setError("Server Error");
    }
  };
  //#endregion

  //#region API to get chat history
  const fetchChatHistory = async (id) => {
    setMainLoader(true);
    try {
      const { data } = await axios.get(`/conversations/${id}`);

      if (data?.conversation_id === id) {
        const isGraphGenerated = data?.messages?.find((i) => (i?.ai?.timeline || i?.graph) && true);

        setIsConversationConclude(!!isGraphGenerated);

        setMessages((prevVal) => ({
          ...prevVal,
          chatHistory: data?.messages,
          feedback: data?.feedback,
        }));
      }
      setMainLoader(false);
    } catch (error) {
      toast.error("Server Error", custom_style.toast_error);
      setMainLoader(false);
    }
  };
  //#endregion

  //#region API to send feedback
  const sendFeedback = async (text) => {
    try {
      setError("");
      setInputText("");
      const { data } = await axios.post("/feedback", {
        conversation_id: conversationId,
        feedback: text,
      });
      toast.success(data?.data ?? "Submitted", custom_style.toast_success);
      dispatch({
        type: "feedback_modal",
        feedbackModalVisible: false,
        feedbackConversationId: conversationId,
      });
    } catch (error) {
      console.error(error);
    }
  };
  //#endregion

  return (
    <div
      className={`chat-display-container ${!data?.toggleSidebar && "closed"}`}
      id="chat-display-container"
    >
      {mainLoader ? (
        <div className="main-loader"></div>
      ) : (
        <div
          id="botui-app"
          className={`chat-container ${!data?.toggleSidebar && "closed"}`}
        >
          {/* Custom Bot */}
          <RenderBot
            conversationId={conversationId}
            messages={messages}
            loader={isLoading}
            handleSubmit={(data) => {
              const filteredData = data.filter((i) => i && i?.answer);
              const humanReply = filteredData
                .map((obj) => obj.answer.trim())
                .join("\n")
                .trim();
              const submittedAnswer = filteredData
                ?.map(
                  (query) =>
                    `Question: ${
                      query?.question
                    }\nAnswer: ${query?.answer.trim()}`
                )
                .join("\n\n")
                .trim();
              sendToAPI(humanReply, null, submittedAnswer);
            }}
            handleConclude={(value, text) => sendToAPI(text, [], null, value)}
            handleFeedback={(value) =>
              dispatch({
                type: "feedback_modal",
                feedbackModalVisible: value,
                feedbackConversationId: conversationId,
              })
            }
          />
        </div>
      )}
      {/* Bottom Input Box for Asking query and Also for sending feedback */}
      {((storedSessionId.includes(conversationId) &&
        !mainLoader &&
        !isConversationConclude) ||
        (data?.feedbackModalVisible &&
          data?.feedbackConversationId === conversationId)) && (
        <InputAction
          text={inputText}
          onInput={(text) => setInputText(text)}
          onSend={(attachment) => {
            if (!isLoading) {
              if (
                data?.feedbackModalVisible &&
                data?.feedbackConversationId === conversationId
              ) {
                sendFeedback(inputText.trim());
              } else {
                sendToAPI(inputText.trim(), attachment);
              }
            }
          }}
          isDisabled={isLoading}
          error={error}
          regenerateMessage={() =>
            sendToAPI(
              messages?.currentMessage,
              messages?.currentAttachments,
              submittedAnswers,
              conversationConcludeCheck
            )
          }
          isFeedback={
            data?.feedbackModalVisible &&
            data?.feedbackConversationId === conversationId
          }
        />
      )}
    </div>
  );
};

export default Chatbox;
