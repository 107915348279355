import React from "react";
import Plot from "react-plotly.js";

const DisplayGraph = ({ graphData }) => {
  const listData = graphData["data"];
  const layoutData = graphData["layout"];
  return (
    <div className="bot bot-response-graph-container">
      <Plot
        data={listData}
        layout={layoutData}
        useResizeHandler
        config={{
          responsive: true,
          autosizable: true,
          displaylogo: false,
          // fillFrame: true,
          watermark: false,
          // fillFrame: true,
          // frameMargins: "100px",
        }}
        style={{ width: "100%", height: "100%"}}
      />
    </div>
  );
};

export default DisplayGraph;
