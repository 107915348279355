export const custom_style = {
  toast_error: {
    style: { backgroundColor: "crimson", color: "#fff", fontSize: "14px" },
    iconTheme: { primary: "#fff", secondary: "crimson" },
  },
  toast_success: {
    style: { backgroundColor: "#4BB543", color: "#fff", fontSize: "14px" },
    iconTheme: { primary: "#fff", secondary: "#4BB543" },
  },
};
