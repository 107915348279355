import { useRef } from "react";
import { RxExternalLink } from "react-icons/rx";
import { camelCaseToTitle, toInternationalFormat } from "../../helper";
import { Config } from "../../config";

// Using helper components for displaying messages in different formats

export const RenderArrayWithObject = ({ data }) => {
  return data?.map((item, index) => (
    <li className="bot-response-list" key={index}>
      <b className="bot-response-list-header">{item?.title}: </b>
      {item?.content}
    </li>
  ));
};

export const RenderArrayForTable = ({ data, orderOfColumn }) => {
  return (
    <li className="bot-response-list">
      <table className="bot-response-table">
        <thead>
          <tr className="bot-response-table-row">
            {orderOfColumn?.map(
              (i, index) =>
                <th className="bot-response-table-header" key={index}>
                  {camelCaseToTitle(i).toUpperCase()}
                </th>
            )}
          </tr>
        </thead>
        <tbody>
          {data?.map((col, index) => (
            <tr className="bot-response-table-row" key={index}>
              {orderOfColumn.map((i, index) => {
                return (
                  // TEMPORARY CHECK FOR COST PER RESOURCE
                  <td className="bot-response-table-col" key={index}>
                    {typeof col[i] === "number" &&
                      !['totalEffortHours', 'numberOfResourcesRequired' ].includes(i) &&
                      col[i] > 0
                      ? `${Config.IND_CURRENCY}${toInternationalFormat(col[i])}`
                      : col[i]}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </li>
  );
};

export const RenderArrayForInput = ({ data, inputData, value, isVisible }) => {
  const textareaRefs = useRef([]);

  const adjustTextareaHeight = (index) => {
    const textarea = textareaRefs.current[index];
    if (textarea) {
      textarea.style.height = "auto"; // Reset height
      textarea.style.height = `${textarea?.scrollHeight}px`; // Set to scrollHeight
    }
  };

  return (
    data?.questions &&
    data?.questions?.map((item, index) => (
      <li className="bot-response-list" key={index}>
        <p>{item}</p>
        {isVisible && (
          <div className="bot-response-input-box-container">
            <textarea
              ref={(el) => (textareaRefs.current[index] = el)}
              placeholder={data?.hints && data?.hints[index]}
              className="bot-response-input-box"
              value={value[index]?.answer}
              onChange={(e) => {
                adjustTextareaHeight(index);
                inputData(index, item, e.target.value);
              }}
              rows={1}
            ></textarea>
          </div>
        )}
      </li>
    ))
  );
};

export const RenderArrayList = ({ data }) => {
  return data?.map((item, index) => (
    <li className="bot-response-list" key={index}>
      {item}
    </li>
  ));
};

export const ArrayObjectWithUrl = ({ data, openLink }) => {
  return data?.map((item, index) => (
    <li className="bot-response-list" key={index}>
      <span className="bot-response-list-title">
        <b className="bot-response-list-header">{item?.title}: </b>
        {item?.url && (
          <RxExternalLink
            className="bot-response-link-button"
            onClick={() => openLink(item?.url)}
          />
        )}
      </span>
      <p>{item?.details?.summary}</p>
    </li>
  ));
};
