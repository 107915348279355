// Function to sort data according to date
export const DateWiseSort = (data, fieldName) => {
  const sorted = data.sort((a, b) => {
    return new Date(b[fieldName]) - new Date(a[fieldName]);
  });
  return sorted;
};

// Function to validate json and return json if it is string
export const checkJsonValidation = (data) => {
  if (typeof data === "string") {
    return JSON.parse(data);
  } else {
    return data;
  }
};

// Extract extension
export const extractExt = (item, regExStr = null) => {
  // let regEx = /(?:.([^.]+))?$/;
  let regEx = regExStr ?? /\/([a-zA-Z0-9]+)$/;
  try {
    const result = regEx.exec(item)[1];
    return result;
  } catch (err) {
    return null;
  }
};

// Function to convert amount to International format
export const toInternationalFormat = (amount) => {
  return new Intl.NumberFormat("en-US", { style: "decimal" }).format(amount);
};

// Function to convert amount to Indian format
export const toIndianFormat = (amount) => {
  return new Intl.NumberFormat("en-IN", {
    style: "decimal",
    maximumFractionDigits: 2,
  }).format(amount);
};

// Function to create url of item
export const createUrl = (data) => {
  try {
    const url = URL.createObjectURL(data);
    return url;
  } catch (error) {
    return "";
  }
};

// Function to convert camelCase to title case
export const camelCaseToTitle = (s) => {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};
