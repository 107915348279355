// For production build make Is_Prod = true
const Is_Prod = true;

export const Config = {
  // API
  API_URL: Is_Prod ? "https://api-neo.ajackus.com/v2" : "http://13.202.56.37:7860/v2", //"http://localhost:7860",


  // EXTENSIONS
  IMAGE_EXTENSION: ["png", "jpg", "jpeg"],
  DOC_EXTENSION: [],
  COMPRESS_FILE_EXTENSION: ["zip", "tar"],
  IND_CURRENCY: "₹",
  USD_CURRENCY: "$",
};
