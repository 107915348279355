// Import Styles
import "../styles/sidebar-style.css";

import React, { useEffect, useState } from "react";
import { TbLayoutSidebarFilled } from "react-icons/tb";
import { MdEditSquare } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

import { instance as axios } from "../lib/axios";
import { DateWiseSort } from "../helper";

const Sidebar = ({}) => {
  const [toggleSidebar, setToggleSidebar] = useState(true);
  const [listConversations, setListConversations] = useState([]);
  const sessionId = useSelector((state) => state?.sessionId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pass = window.localStorage.getItem("authPassword");

  //#region Api to fetch list of history conversations
  const fetchAllConversations = async () => {
    try {
      const response = await axios.get("/conversations");

      if (response?.data) {
        let todays = moment().format("DD/MM/YY");
        let yesterdays = moment().subtract(1, "days");
        const conversations = [
          {
            name: "today",
            data: DateWiseSort(
              response?.data?.conversations?.filter((item) => {
                return moment(item?.createdAt).format("DD/MM/YY") === todays;
              }),
              "createdAt"
            ),
          },
          {
            name: "yesterday",
            data: DateWiseSort(
              response?.data?.conversations?.filter((item) => {
                return (
                  moment(item?.createdAt).format("DD/MM/YY") ===
                  yesterdays.format("DD/MM/YY")
                );
              }),
              "createdAt"
            ),
          },
          {
            name: "older",
            data: DateWiseSort(
              response?.data?.conversations?.filter((item) => {
                return moment(item?.createdAt).isSameOrBefore(
                  moment().subtract(2, "days")
                );
              }),
              "createdAt"
            ),
          },
        ];

        setListConversations(conversations);
      }
    } catch (error) {}
  };
  //#endregion

  useEffect(() => {
    fetchAllConversations();
  }, [sessionId]);

  const handleScreenResize = () => {
    if (window.innerWidth <= 750) {
      setToggleSidebar(false);
    } else {
      setToggleSidebar(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleScreenResize);
    handleScreenResize();

    return () => window.removeEventListener("resize", handleScreenResize);
  }, []);

  useEffect(() => {
    dispatch({
      type: "toggle_sidebar",
      toggleSidebar: toggleSidebar,
    });
  }, [toggleSidebar, dispatch]);

  return (
    <div className={`sidebar ${!toggleSidebar && "closed"}`} id="sidebar">
      {/* Sidebar Top Header */}
      <div
        className={`sidebar-top-btns ${!toggleSidebar && "closed"}`}
        id="sidebar-top-btns"
      >
        <h2>Neo AI</h2>
        <div className="button-container">
          <button
            className="open-btn"
            id="toggleBtn"
            onClick={() => {
              setToggleSidebar(!toggleSidebar);
            }}
          >
            <span className="menu-icon">
              <TbLayoutSidebarFilled />
            </span>
          </button>
          <button
            className="create-new"
            id="newChatBtn"
            onClick={() => navigate(`/${pass && `?pass=${pass}`}`)}
          >
            <span className="edit-icon">
              <MdEditSquare />
            </span>
          </button>
        </div>
      </div>

      {/* List of history conversation */}
      {listConversations?.map((item, index) => {
        return (
          <div
            className={`timeline-container ${!toggleSidebar && "closed"}`}
            key={index}
          >
            {item?.data.length > 0 && (
              <p className="timeline-text">{item?.name}</p>
            )}
            {item?.data?.map((a, index) => {
              return (
                <div
                  key={index}
                  className={`conversation-topic-container ${
                    sessionId === a?.conversation_id && "highlight"
                  }`}
                  onClick={() => {
                    navigate(
                      `/conversation/${a?.conversation_id}${
                        pass && `?pass=${pass}`
                      }`
                    );
                  }}
                >
                  <a>{a?.conversation_topic}</a>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Sidebar;
