import React, { useEffect, useRef, useState } from "react";
import { Config } from "../../config";
import { checkJsonValidation, createUrl, extractExt } from "../../helper";
import DisplayGraph from "./DisplayGraph";
import DisplayMessages from "./DisplayMessages";
import DisplayAttachment from "./DisplayAttachment";
import DisplayConcludeMessage from "./DisplayConcludeMessage";

const RenderBot = ({
  messages: {
    currentId,
    currentMessage,
    currentAttachments,
    chatHistory,
    feedback,
  },
  conversationId,
  loader,
  handleSubmit,
  handleConclude,
  handleFeedback,
}) => {
  const messagesEndRef = useRef(null);
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(true);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // For making chat to scroll at latest chat at end
  useEffect(() => {
    scrollToBottom();
  }, [chatHistory, currentMessage]);

  // Extract only answers as string contains "Question: abc\nAnswer: xyz"
  const ExtractAnswer = (text) => {
    const lines = text.split(/\r?\n/);
    const answers = [];

    // Loop through each line and check if it starts with "Answer:"
    lines.forEach((line) => {
      if (line.startsWith("Answer:")) {
        const answer = line.replace("Answer:", "").trim();
        answers.push(answer);
      }
    });

    if (answers.length > 0) {
      return answers
        ?.filter((i) => i)
        .join("\n")
        .trim();
    } else {
      if (text === "Conclusion") {
        return "Yes";
      }
      return text;
    }
  };

  return (
    <div className="bot_container">
      {chatHistory?.map(({ ai, attachments, graph, user }, index) => {
        const graphData = ai.timeline || graph
        return (
          <div className="botui_message" key={index}>
            {/* Human Message From Api */}
            <div className="human_content_box">
              {user && (
                <div className="botui_message_content human">
                  {attachments?.length > 0 && (
                    <div className="attachment">
                      {attachments?.map((item, index) => (
                        <DisplayAttachment
                          key={index}
                          name={item?.name ?? item?.fileName}
                          url={item?.url ?? createUrl(item)}
                          isImage={Config.IMAGE_EXTENSION.includes(
                            extractExt(
                              item?.name ?? item?.fileName,
                              /(?:.([^.]+))?$/
                            )
                          )}
                          isDoc={Config.DOC_EXTENSION.includes(
                            extractExt(
                              item?.name ?? item?.fileName,
                              /(?:.([^.]+))?$/
                            )
                          )}
                        />
                      ))}
                    </div>
                  )}
                  <pre>{ExtractAnswer(String(user))}</pre>
                </div>
              )}
            </div>
            {/* Bot Message */}
            <div className="bot_content_box">
              {graphData && <DisplayGraph graphData={checkJsonValidation(graphData)} />}
              {ai && (
                <DisplayMessages
                  message={ai?.message ?? ai}
                  questions={ai?.questions}
                  teamStructure={ai?.teamStructure}
                  technicalSpecifications={ai?.technicalSpecifications}
                  potentialRisks={ai?.potentialRisks}
                  caseStudies={ai?.caseStudies}
                  costEstimation={ai?.costEstimation}
                  estimation={ai.estimation}
                  notes={ai?.notes}
                  suggestions={ai?.suggestions}
                  assumptions={ai?.assumptions}
                  onSubmit={(data) => !loader && handleSubmit(data)}
                  isLast={index === chatHistory.length - 1}
                  hideQuestions = {!!graphData}
                  isVisible={true} //{graph ? true : false}
                />
              )}
              {/* Prompt for asking user want to conclude message or not */}
              {ai?.isConversationReadyForConclusion &&
                index === chatHistory.length - 1 &&
                !graphData &&
                !loader && (
                  <DisplayConcludeMessage
                    message={
                      "Based on the inputs provided for the questions, can we conclude the conversation and proceed with the estimation?"
                    }
                    onSelect={(value, text) => handleConclude(value, text)}
                  />
                )}
              {/* Prompt for asking if user want to share feedback */}
              {graphData &&
                index === chatHistory.length - 1 &&
                isFeedbackVisible &&
                feedback === null && (
                  <DisplayConcludeMessage
                    message={"Do you want to share feedback?"}
                    onSelect={(value) => {
                      setIsFeedbackVisible(false);
                      handleFeedback(value);
                    }}
                  />
                )}
            </div>
          </div>
        );
      })}
      {/* Real-time Human message */}
      {currentId === conversationId && (
        <div>
          {currentMessage && (
            <div className="human_content_box">
              <div className="botui_message_content human">
                {currentAttachments?.length > 0 && (
                  <div className="attachment">
                    {currentAttachments?.map((item, index) => (
                      <DisplayAttachment
                        key={index}
                        name={item?.name ?? item?.fileName}
                        url={item?.url ?? URL.createObjectURL(item)}
                        isImage={Config.IMAGE_EXTENSION.includes(
                          extractExt(
                            item?.name ?? item?.fileName,
                            /(?:.([^.]+))?$/
                          )
                        )}
                        isDoc={Config.DOC_EXTENSION.includes(
                          extractExt(
                            item?.name ?? item?.fileName,
                            /(?:.([^.]+))?$/
                          )
                        )}
                      />
                    ))}
                  </div>
                )}
                <pre>{currentMessage}</pre>
              </div>
            </div>
          )}
          {/* Display loader while bot response not loaded */}
          {loader && (
            <div className="bot">
              <div className="loader"></div>
            </div>
          )}
        </div>
      )}

      <div ref={messagesEndRef} />
      {/* )} */}
    </div>
  );
};

export default RenderBot;
