import React from "react";

import { IoClose } from "react-icons/io5";
import { FaRegFolder } from "react-icons/fa6";
import { FiFileText } from "react-icons/fi";
import { LuFileQuestion } from "react-icons/lu";

const AddedAttachment = ({
  onHover,
  onLeave,
  isHovered,
  deleteItem,
  imgUrl,
  name,
  isImage,
  isDoc,
}) => {
  return (
    <div
      className={`${
        isImage
          ? "uploaded-file-image-container"
          : "uploaded-file-item-container"
      }`}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      {isImage ? (
        <img src={imgUrl} className="uploaded-file-image" />
      ) : (
        <div className="uploaded-file-detail">
          <span className="icon-container doc">
            {isDoc && <FiFileText className="icon" size={"18px"} />}
          </span>
          <span className="file-name">{name}</span>
        </div>
      )}

      {isHovered && (
        <IoClose className="uploaded-file-close-icon" onClick={deleteItem} />
      )}
    </div>
  );
};

export default AddedAttachment;
