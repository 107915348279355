// Import Styles
import "../styles/style.css";

// Import Components
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { instance as axios } from "../lib/axios";
import { InputAction, RenderBot } from "../components";

const StartChatbox = ({}) => {
  const [inputText, setInputText] = useState("");
  const { conversationId } = useParams();
  const [messages, setMessages] = useState({
    currentId: "",
    currentMessage: "",
    currentAttachments: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pass = window.localStorage.getItem("authPassword");
  let storedSessionId =
    JSON.parse(window.localStorage.getItem("sessionIds")) || [];

  const data = useSelector((state) => state);

  useEffect(() => {
    dispatch({ type: "update_session", sessionId: conversationId });
  }, [conversationId]);

  //#region Api to start the conversation
  const sendToAPI = async (humanText, attachments) => {
    try {
      setError("");
      if (humanText.trim() === "" || isLoading) {
        return;
      }
      setInputText("");

      const formData = new FormData();
      formData.append("user", humanText);
      formData.append("conversation_id", conversationId ?? "");

      if (attachments && attachments.length > 0) {
        attachments.forEach((attachment, index) => {
          formData.append(`attachment_${index + 1}`, attachment);
        });
      }

      setMessages((prevVal) => ({
        ...prevVal,
        currentId: conversationId,
        currentMessage: humanText,
        currentAttachments: attachments,
      }));
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("/conversations", formData, config);

      storedSessionId.push(data?.conversation_id);
      window.localStorage.setItem(
        "sessionIds",
        JSON.stringify(storedSessionId)
      );

      setIsLoading(false);

      // Navigation to chat history route
      navigate(
        `/conversation/${data?.conversation_id}${pass && `?pass=${pass}`}`
      );
    } catch (err) {
      setIsLoading(false);
      console.log("Error", err?.message);
      setError("Server Error");
    }
  };
  //#endregion

  return (
    <div
      className={`chat-display-container ${!data?.toggleSidebar && "closed"}`}
      id="chat-display-container"
    >
      <div
        id="botui-app"
        className={`chat-container ${!data?.toggleSidebar && "closed"}`}
      >
        {/* Custom Bot */}
        <RenderBot
          messages={messages}
          conversationId={conversationId}
          loader={isLoading}
        />
      </div>
      {/* Bottom Input Box for Asking query */}
      <InputAction
        text={inputText}
        onInput={(text) => setInputText(text)}
        onSend={(attachment) =>
          !isLoading && sendToAPI(inputText.trim(), attachment)
        }
        isDisabled={isLoading}
        error={error}
        regenerateMessage={() =>
          sendToAPI(messages?.currentMessage, messages?.currentAttachments)
        }
      />

      {/* Welcome text if chat board is empty */}
      <div className="welcome-message" id="welcomeMessage">
        <h1>Welcome to Neo AI</h1>
      </div>
    </div>
  );
};

export default StartChatbox;
