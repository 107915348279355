import React from "react";

const concludeButton = [
  {
    text: "Yes",
    value: true,
  },
  {
    text: "No",
    value: false,
  },
];

const DisplayConcludeMessage = ({ message, onSelect }) => {
  return (
    <div className="bot-conclude-message-container">
      <div className="conclude-message-text">
        <p>{message}</p>
      </div>
      <div className="conclude-message-button-container">
        {concludeButton?.map(({ text, value }, index) => (
          <button
            key={index}
            className="conclude-message-button"
            onClick={() => onSelect(value, text)}
          >
            {text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DisplayConcludeMessage;
